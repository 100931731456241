import ModelBase from "@/apps/core/models/modelBase.js";

const modelName = "aktivitas";
const modelPath = `/${modelName}/`;

class Aktivitas extends ModelBase {
  constructor() {
    let model = {
        id: null,
        perawatan: {
          id: null,
          kode_bill: "",
          jenis_lokasi: "",
          pasien: {
            id: null,
            name: "",
            no_rm: ""
          }
        },
        stase: "",
        sub_departemen: {id: null, nama: ""},
        kompetensi: "",
        verifikasi: "",
        verifikasi_input: "",
        jenis_keterampilan: {id: null, nama: ""},
        tanggal: "",
        preceptor: {id: null, nama: ""},
        pspd_id: null,
        pspd: "",
        diagnosis_set: [],
        pemeriksaan_set: [],
        tatalaksana_set: [],
        keterampilan_set: [],
        extra_data: {}
    };
    super(modelName, model, ["catatan"], ["verifikasi_input"], modelPath);
  }

  getLoadData(data) {
    let aktivitas = super.getLoadData(data);
    aktivitas.verifikasi_input = aktivitas.verifikasi;
    return aktivitas;
  }
  
  getPayload() {
    let aktivitas = this.observables.aktivitas;
    let payLoadKet = {};
    let ver = aktivitas.verifikasi_input;
    if (ver !== 'Belum') {
      let aktivitasSet = aktivitas.keterampilan_set.filter(
        ak => ak.verifikasi === 'Belum');
      for (let akt of aktivitasSet) {
        payLoadKet[akt.id] = {"verifikasi": ver};
      }
    }
    let data = {data_set: payLoadKet};
    return data;
  }
}

export default Aktivitas;
