<template>
  <div class="akt-detail">
    <div class="columns no-margin">
      <div class="column no-padding is-full">
        <div class="card">
          <header class="card-header has-background-primary">
            <div @click.stop="$router.go(-1)" class="card-header-icon">
              <b-icon class="has-text-light" size="is-small" icon="arrow-left" />
            </div>
            <p class="card-header-title has-text-light">AKTIVITAS DETAIL</p>
            <template v-if="isLoaded && aktivitas.extra_data.can_change">
              <router-link
                v-if="aktivitas.perawatan == null"
                :to="{ name: 'aktivitas-change', params: { id: aktivitas.id } }"
                class="card-header-icon has-text-light"
              >
                <b-icon class="has-text-light" size="is-small" icon="edit" />
              </router-link>
              <router-link
                v-else-if="aktivitas.perawatan.jenis_perawatan == 'jalan'"
                :to="{ name: 'aktivitas-jalan-change', params: { id: aktivitas.id } }"
                class="card-header-icon has-text-light"
              >
                <b-icon class="has-text-light" size="is-small" icon="edit" />
              </router-link>
              <router-link
                v-else
                :to="{ name: 'aktivitas-inap-change', params: { id: aktivitas.id } }"
                class="card-header-icon has-text-light"
              >
                <b-icon class="has-text-light" size="is-small" icon="edit" />
              </router-link>
              <a class="card-header-icon" @click.stop.prevent="confirmDelete">
                <b-icon class="has-text-light" size="is-small" icon="trash" />
              </a>
            </template>
          </header>
          <div class="card-content events-card">
            <div class="columns is-mobile">
              <div class="column detail is-4 has-text-weight-semibold">Stase</div>
              <div class="column detail has-text-weight-semibold is-1">:</div>
              <div class="column detail has-text-left">{{ aktivitas.stase }}</div>
            </div>
            <template v-if="aktivitas.perawatan">
              <div class="columns is-mobile">
                <div class="column detail has-text-weight-semibold is-4">Kode Bill</div>
                <div class="column detail has-text-weight-semibold is-1">:</div>
                <div class="column detail has-text-left">{{ aktivitas.perawatan.kode_bill }}</div>
              </div>
              <div class="columns is-mobile">
                <div class="column detail is-4 has-text-weight-semibold">Lokasi</div>
                <div class="column detail has-text-weight-semibold is-1">:</div>
                <div class="column detail has-text-left">{{ aktivitas.perawatan.jenis_lokasi }}</div>
              </div>
              <div class="columns is-mobile">
                <div class="column detail is-4 has-text-weight-semibold">Pasien</div>
                <div class="column detail has-text-weight-semibold is-1">:</div>
                <div class="column detail has-text-left is-capitalized">
                  <router-link
                    v-if="aktivitas.perawatan.pasien.id"
                    :to="{ name: 'pasien-detail', params: { id: aktivitas.perawatan.pasien.id } }"
                  >{{ aktivitas.perawatan.pasien.nama }}</router-link>
                </div>
              </div>
              <div class="columns is-mobile">
                <div class="column detail is-4 has-text-weight-semibold">No RM</div>
                <div class="column detail has-text-weight-semibold is-1">:</div>
                <div class="column detail has-text-left">{{ aktivitas.perawatan.pasien.no_rm }}</div>
              </div>
            </template>
            <div class="columns is-mobile">
              <div class="column detail is-4 has-text-weight-semibold">Preceptor</div>
              <div class="column detail has-text-weight-semibold is-1">:</div>
              <div class="column detail has-text-left">{{ aktivitas.preceptor.nama }}</div>
            </div>
            <div class="columns is-mobile">
              <div class="column detail is-4 has-text-weight-semibold">Tanggal</div>
              <div class="column detail has-text-weight-semibold is-1">:</div>
              <div class="column detail has-text-left">{{ aktivitas.tanggal }}</div>
            </div>
            <div class="columns is-mobile">
              <div class="column detail is-4 has-text-weight-semibold">Mahasiswa</div>
              <div class="column detail has-text-weight-semibold is-1">:</div>
              <div class="column detail has-text-left">{{ aktivitas.pspd }}</div>
            </div>
            <div class="columns is-mobile">
              <div class="column detail is-4 has-text-weight-semibold">Kompetensi</div>
              <div class="column detail has-text-weight-semibold is-1">:</div>
              <div
                class="column detail has-text-left"
              >{{ aktivitas.kompetensi }} [{{aktivitas.jenis_keterampilan.nama}}]</div>
            </div>
            <div v-if="canVerifikasi" class="columns is-mobile">
              <div class="column detail is-4 has-text-weight-semibold">Verifikasi</div>
              <div class="column detail has-text-weight-semibold is-1">:</div>
              <div class="column detail has-text-left">
                <div class="no-padding">
                  <b-select v-model="aktivitas.verifikasi_input">
                    <option value="Belum">Ver: -</option>
                    <option value="Ya">Ver: ✓</option>
                    <option value="Tidak">Ver: ✗</option>
                  </b-select>
                </div>
              </div>
            </div>
            <div v-if="canVerifikasi" class="columns is-mobile">
              <div class="column detail is-4 has-text-weight-semibold"></div>
              <div class="column detail has-text-weight-semibold is-1"></div>
              <div class="column detail has-text-left">
                <b-button
                  class="is-primary has-text-weight-semibold"
                  @click.stop.prevent="saveObject"
                >Simpan</b-button>
              </div>
            </div>
            <div
              v-if="aktivitas.komponen_foto_list && aktivitas.komponen_foto_list.length > 0"
              class="columns is-mobile"
            >
              <div class="column">
                <a
                  @click.stop.prevent="openGallery"
                  class="is-pulled-right primary-btn card-header-icon"
                >
                  <b-icon title="Tampilkan gambar" custom-size="2x" size="is-small" icon="image" />
                </a>
              </div>
            </div>
            <b-loading :is-full-page="false" :active.sync="loading"></b-loading>
          </div>
        </div>
      </div>
    </div>
    <template v-if="isLoaded">
      <template v-if="!aktivitas.virtual">
        <div class="columns no-margin">
          <div class="column no-padding is-full">
            <div class="card">
              <header class="card-header has-background-primary">
                <p class="card-header-title has-text-light">Diagnosis</p>
                <router-link
                  v-if="aktivitas.extra_data.can_add_komponen"
                  :to="{ name: 'aktivitas-diagnosis-add', params: { aktivitasId: aktivitas.id, subDeptId: aktivitas.sub_departemen.id } }"
                  class="card-header-icon has-text-light"
                >
                  <b-icon class="has-text-light" size="is-small" icon="plus" />
                </router-link>
              </header>
              <div class="content card-content">
                <div class="columns is-mobile">
                  <div class="column">
                    <div v-if="aktivitas.diagnosis_set.length > 0">
                      <b-field grouped group-multiline>
                        <komponen-aktivitas-tag
                          v-for="ak_diag in aktivitas.diagnosis_set"
                          :key="ak_diag.id"
                          :id="ak_diag.id"
                          :namedUrl="'aktivitas-diagnosis-detail'"
                          :aktivitas="aktivitas"
                          :aktKomp="ak_diag"
                          :tagColor="getTagColor(aktivitas.pspd_id, aktivitas.preceptor.id)"
                        />
                      </b-field>
                    </div>
                    <div v-else>Tidak ada data</div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="columns no-margin">
          <div class="column no-padding is-full">
            <div class="card">
              <header class="card-header has-background-primary">
                <p class="card-header-title has-text-light">Pemeriksaan</p>
                <router-link
                  v-if="aktivitas.extra_data.can_add_komponen"
                  :to="{ name: 'aktivitas-pemeriksaan-add', params: { aktivitasId: aktivitas.id } }"
                  class="card-header-icon has-text-light"
                >
                  <b-icon class="has-text-light" size="is-small" icon="plus" />
                </router-link>
              </header>
              <div class="content card-content">
                <div class="columns is-mobile">
                  <div class="column">
                    <div v-if="aktivitas.pemeriksaan_set.length > 0">
                      <b-field grouped group-multiline>
                        <komponen-aktivitas-tag
                          v-for="ak_pem in aktivitas.pemeriksaan_set"
                          :key="ak_pem.id"
                          :id="ak_pem.id"
                          :namedUrl="'aktivitas-pemeriksaan-detail'"
                          :aktivitas="aktivitas"
                          :aktKomp="ak_pem"
                          :tagColor="getTagColor(aktivitas.pspd_id, aktivitas.preceptor.id)"
                        />
                      </b-field>
                    </div>
                    <div v-else>Tidak ada data</div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="columns no-margin">
          <div class="column no-padding is-full">
            <div class="card">
              <header class="card-header has-background-primary">
                <p class="card-header-title has-text-light">Tatalaksana</p>
                <router-link
                  v-if="aktivitas.extra_data.can_add_komponen"
                  :to="{ name: 'aktivitas-tatalaksana-add', params: { aktivitasId: aktivitas.id } }"
                  class="card-header-icon has-text-light"
                >
                  <b-icon class="has-text-light" size="is-small" icon="plus" />
                </router-link>
              </header>
              <div class="content card-content">
                <div class="columns is-mobile">
                  <div class="column">
                    <div v-if="aktivitas.tatalaksana_set.length > 0">
                      <b-field grouped group-multiline>
                        <komponen-aktivitas-tag
                          v-for="ak_tata in aktivitas.tatalaksana_set"
                          :key="ak_tata.id"
                          :id="ak_tata.id"
                          :namedUrl="'aktivitas-tatalaksana-detail'"
                          :aktivitas="aktivitas"
                          :aktKomp="ak_tata"
                          :tagColor="getTagColor(aktivitas.pspd_id, aktivitas.preceptor.id)"
                        />
                      </b-field>
                    </div>
                    <div v-else>Tidak ada data</div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </template>

      <div class="columns no-margin">
        <div class="column no-padding is-full">
          <div class="card">
            <header class="card-header has-background-primary">
              <p class="card-header-title has-text-light">Keterampilan</p>
              <router-link
                v-if="aktivitas.extra_data.can_add_komponen"
                :to="aktKetRouterTo"
                class="card-header-icon has-text-light"
              >
                <b-icon class="has-text-light" size="is-small" icon="plus" />
              </router-link>
            </header>
            <div class="content card-content">
              <div class="columns is-mobile">
                <div class="column">
                  <div v-if="aktivitas.keterampilan_set.length > 0">
                    <b-field grouped group-multiline>
                      <komponen-aktivitas-tag
                        v-for="ak_ket in aktivitas.keterampilan_set"
                        :key="ak_ket.id"
                        :id="ak_ket.id"
                        :namedUrl="'aktivitas-keterampilan-detail'"
                        :aktivitas="aktivitas"
                        :aktKomp="ak_ket"
                        :keterampilan="true"
                        :tagColor="getTagColor(aktivitas.pspd_id, aktivitas.preceptor.id)"
                      />
                    </b-field>
                  </div>
                  <div v-else>Tidak ada data</div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </template>
  </div>
</template>


<script>
import GLightbox from "glightbox";
import { mapGetters, mapState } from "vuex";
import createUpdateMixin from "@/apps/core/mixins/createUpdateMixin";
import Aktivitas from "../models/aktivitasDetail.js";

export default {
  name: "AktivitasDetail",
  props: ["title"],
  mixins: [createUpdateMixin],
  components: {
    KomponenAktivitasTag: () =>
      import("@/apps/aktivitas/components/KomponenAktivitasTag.vue"),
  },
  data() {
    this.aktivitasMdl = new Aktivitas();
    this.objectMdl = this.aktivitasMdl;
    return this.aktivitasMdl.getObservables();
  },
  computed: {
    ...mapState("accounts", ["userId"]),
    ...mapGetters("accounts", ["isPSPD", "isPreceptor"]),
    aktKetRouterTo() {
      const params = {
        aktivitasId: this.aktivitas.id,
        subDeptId: this.aktivitas.sub_departemen.id
      };
      if (this.aktivitas.perawatan) {
        return { name: "aktivitas-keterampilan-add", params: params };
      }
      return { name: "keterampilan-virtual-add", params: params };
    },
    canVerifikasi() {
      return (
        this.userId == this.aktivitas.preceptor_id &&
        this.aktivitas.verifikasi === "Belum"
      );
    }
  },
  methods: {
    confirmDelete() {
      this.$buefy.dialog.confirm({
        title: "Menghapus Data",
        message: `Anda yakin akan <b>menghapus</b> ${this.title.toLowerCase()} ini?`,
        confirmText: `Hapus ${this.title}`,
        type: "is-danger",
        hasIcon: true,
        onConfirm: () => {
          this.objectMdl.delete(this.$route.params.id, (data) => {
            data.redirect_url? this.$router.replace({"name": data.redirect_url}) : this.$router.go(-1);
          }
          );
        }
      });
    },
    onLoaded() {
      const apiURL = `${this.aktivitasMdl.getUpdateUrl()}verifikasi/`;
      this.aktivitasMdl.setUpdateUrl(apiURL);
    },
    fetchData() {
      this.aktivitasMdl.load(this.$route.params.id, this.onLoaded);
    },
    onSaved() {
      this.aktivitas.verifikasi = this.aktivitas.verifikasi_input;
      let aktivitasSet = this.aktivitas.keterampilan_set.filter(
        ak => ak.verifikasi === "Belum"
      );
      for (let akt of aktivitasSet) {
        akt.verifikasi = this.aktivitas.verifikasi;
      }
    },
    getTagColor(pspd_id, preceptor_id) {
      return this.userId == pspd_id || this.userId == preceptor_id;
    },
    openGallery() {
      this.glightbox.setElements(this.aktivitas.komponen_foto_list);
      this.glightbox.open();
    },
  },
  mounted() {
    this.glightbox = GLightbox();
  },
  updated() {
    this.glightbox.reload();
  }
};
</script>

<style scoped lang="scss">
@import "~glightbox/dist/css/glightbox.min.css";
@import "../../core/assets/css/glightbox.css";
::v-deep .is-grouped-multiline {
  margin-bottom: unset !important;
}

table td.add-btn {
  max-width: 50px;
  text-align: right;

  a {
    padding-top: 2px;
  }
}

.float {
  display: inline-block;
  width: 20px;
  height: 20px;
  background-color: #00d1b2;
  border-radius: 50px;
  text-align: center;
}

.primary-btn {
  color: #00d1b2 !important;
}
</style>
